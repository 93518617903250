import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import './Values.scss';

export class Values extends React.Component {
  render() {
    return (
      <section id="values-section" data-id={this.props.dataId} data-title={this.props.dataTitle}>
        <Waypoint onEnter={(a) => this.props.onSectionEnter(a, this.props.dataId)} topOffset={this.props.topOffset} />
        <Container className="container-wrapper">
          <h1>{this.props.dataTitle}</h1>
          <Row>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Innovators beat ideas</h2>
              <p>Greate ideas do not fly without passionate Innovators pioneering the field.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Data beats opinions</h2>
              <p>Emperical data is the foundation of rational decision making processes
                in the enterprise.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Doing beats talking</h2>
              <p>Action leads to results.  Results are progress.  Failure is an option,
                the earlier the better.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Simple beats complex</h2>
              <p>Minimizing complexities of dynamic systems is always a good call.
                Scarcity brings clarity.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Great teams are diverse</h2>
              <p>Members from different professional backgrounds and perspectives add
                great value to the team.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Great teams share ownership</h2>
              <p>Successful teams develop a culture of shared problem and solution
                ownership.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Great teams provide guidance</h2>
              <p>Experienced members discretely mentor their peers and stakeholders
                and improve coherence.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Great teams adapt to change</h2>
              <p>Team members are connected and reach out to involve new experts for fresh ideas.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Good processes drive innovation</h2>
              <p>Incorporating contemporary practices and common sense will provide
                opportunities for growth.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Good processes are meaningful</h2>
              <p>Processes which are meaningful will drive adoption and create tangible
                value for everyone involved.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Good processes are agile</h2>
              <p>Processes which adapt to changing realities and scale with growth are
                fundamental for business agility.</p>
            </Col>
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
              <h2>Good processes are lean</h2>
              <p>Reducing complexity to a minimum means processes are more easy to
                understand and focus on the vision.</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
