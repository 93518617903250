import { Container, Row, Col } from 'react-bootstrap'
import { Waypoint } from 'react-waypoint';
import './Contact.scss'

export function Contact(props) {
  return (
    <section id='contact-section' data-id={props.dataId} data-title={props.dataTitle}>
      <Waypoint onEnter={(a) => props.onSectionEnter(a, props.dataId)} topOffset={props.topOffset} />
      <Container id='contact-container' className='container-wrapper'>
        <h1>Contact</h1>
        <Row>
          <Col xs="12" sm="12" md="4" lg="3" xl="3" className="">
            <h2>Address</h2>
            <p>Steve Graegert<br />
              Schweizer Tal 14<br />
              13127 Berlin, Germany<br /><br />
              +49 176 72288891<br />
              <a href="mailto:stv@grgrt.com" className="brand-color">
                stv@<span style={{ display: 'none' }}>removeme.</span>grgrt.com</a>
            </p>
            <p className="fine-print">
              Copyright &copy; 2006-present, GRGRT Business Design.<br />Handcrafted with <span className="brand-color">♡</span> in Berlin.
            </p>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3" xl="3" className="d-none d-lg-block">&nbsp;</Col> {/* hide on screens smaller than lg */}
          <Col xs="12" sm="12" md="4" lg="3" xl="3" className="">
            <h2>Legal</h2>
            <p>This site does not integrate with external services and does not track visits at all. Your IP address you connect with will be known to the web server and used to serve page contents. </p>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3" xl="3">
            <h2>Enquiries</h2>
            <p>I provide high quality services and only work where I can create value. To engage my services, discuss your project or organisation please do not hesitate to get in touch. I am always optimizing my approach and collaborating with others to innovate further.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
