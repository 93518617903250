/* global Modernizr:true */
import $ from 'jquery';

import config from '../config';

let state = undefined;

export class ScrollingUtil {
  constructor() {
    state = {
      isSectionScrolling: false,
      scrollLocked: false,
      targetSectionId: 0
    };
  }

  disableScrolling() {
    if (window.addEventListener) {
      window.addEventListener('DOMMouseScroll', ScrollingUtil.preventDefault, false);
      window.addEventListener('wheel', ScrollingUtil.passiveEventListener, Modernizr.passiveeventlisteners ? { passive: true } : false);
      window.addEventListener('mousewheel', ScrollingUtil.passiveEventListener, Modernizr.passiveeventlisteners ? { passive: true } : false);
      window.addEventListener('touchmove', ScrollingUtil.passiveEventListener, Modernizr.passiveeventlisteners ? { passive: true } : false);
    }

    document.onkeydown = this.preventDefaultForScrollKeys;
    state.scrollLocked = true;
  }

  enableScrolling() {
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', ScrollingUtil.preventDefault, false);
      window.removeEventListener('wheel', ScrollingUtil.passiveEventListener, false);
      window.removeEventListener('mousewheel', ScrollingUtil.passiveEventListener, false);
      window.removeEventListener('touchmove', ScrollingUtil.passiveEventListener, false);
    }

    document.onkeydown = null;
    state.scrollLocked = false;
  }

  /**
   * Custom implementation for `e.preventDefault` we can call without an EventArg.
   *
   * @param {Event} e Instance of the Event for which the handler was called.
   */
  static preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    //e.returnValue = false;
  }

  /**
   * Handles events operating in passive mode.
   *
   * @param {Event} e Instance of the Event for which the handler was called.
   */
  static passiveEventListener(e) {
    e.returnValue = false;
  }

  /**
   * Handles keyboard events triggered for this.
   *
   * @param {Event} e
   * @returns {boolean}
   */
  preventDefaultForScrollKeys(e) {
    if (config.app.scrollKeys[e.keyCode]) {
      this.preventDefault(e);
      return false;
    }
  }

  /**
   * Replaces the page header with the section header.
   *
   * @param {object} sectionId The zero-based index of the section to change
   * title of.
   */
  changeSectionHeader(sectionId) {
    let $pageHeader = $(`#header`);
    let currentPageTitle = $pageHeader.text().trim();
    let newPageTitle = $('section').filter(`[data-id="${sectionId}"]`).data('title');

    if (currentPageTitle !== newPageTitle) {
      $pageHeader.stop().fadeOut(100, function () {
        $(this).text(newPageTitle).fadeIn();
      });
    }
  }

  static setIsSectionScrolling(isSectionScrolling) {
    state.isSectionScrolling = isSectionScrolling;
  }

  /**
   *
   * @param point
   * @param cb
   */
  scrollToPoint(point, cb) {
    let that = this;

    if (state.isSectionScrolling) {
      return;
    }
    this.disableScrolling();
    ScrollingUtil.setIsSectionScrolling(true);

    let hasFinished = false;

    $('html, body').animate({
      scrollTop: point
    }, 500, function () {

      if (hasFinished) {
        return;
      }
      hasFinished = true;
      that.enableScrolling();
      ScrollingUtil.setIsSectionScrolling(false);

      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  /**
   * Scroll to the section specified by ID.
   * The optional callback will be called after scrolling completes,
   * but simultaneously with the target's header change.
   *
   * @param {number} sectionId  The ID of the section to scroll to.
   * @param {function} cb  Optional callback invoked in case a scrolling op is in progress
   */
  scrollToSection(sectionId, cb) {
    const that = this;
    const $section = $('section').filter(`[data-id=${sectionId}]`).first();

    if (state.isSectionScrolling) {
      return;
    }

    if ($section.length > 0) {
      this.scrollToPoint($section.offset().top, () => {
        that.changeSectionHeader(sectionId);

        if (typeof cb === 'function') {
          cb();
        }
      });
    }
  }
}
