import { useState, useEffect } from 'react';
import $ from 'jquery';

import { Header } from './components/header/Header'
import { Sections } from './components/sections/Sections';
import { ScrollingUtil } from './lib/ScrollingUtil';

import './App.scss';

function App() {
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [topOffset, setTopOffset] = useState(0);
  const [isSectionWrapperVisible, setIsSectionWrapperVisible] = useState(false);
  const sutil = new ScrollingUtil();

  /**
   * Handles the `onSectionEnter` event fired by Waypoints registered with
   * each section.
   *
   * @param {object} options Arguments passed in from Waypoints.
   * @param {number} sectionId The ID of the section to scroll to.
   */
  const onSectionEnter = (options, sectionId) => {
    let targetSectionId = sectionId; // section enters viewport from below

    if (options.currentPosition === 'inside' && options.previousPosition === 'above') {  // section enters viewport from above
      targetSectionId = sectionId - 1
    }

    sutil.scrollToSection(targetSectionId, () => {
      setCurrentSectionId(targetSectionId);
    });
  }

  /**
   * Handles onSelect events fired by navbar.
   *
   * @param {number} sectionId The ID of the section to scroll to.
   */
  const onNavItemSelect = (sectionId) => {
    sutil.scrollToSection(sectionId, () => {
      setCurrentSectionId(sectionId);
    });
  }

  /**
   * Handles the `window.onResize` events.  It determines proper section
   * height required for smooth scrolling and scroll locking.
   */
  const resizeSections = () => {
    const numberOfSections = 5;

    $("section").each(function () {
      const dataId = parseInt($(this).data("id"), 10);
      const $containerWrapper = $(this).find(".container-wrapper");
      const headerContainerOuterHeight = $("#header-container").outerHeight();
      let targetHeight = undefined;

      if (dataId !== numberOfSections - 1) {
        targetHeight = $containerWrapper.outerHeight() + $(window).innerHeight() - headerContainerOuterHeight;
      } else {
        targetHeight = $(window).innerHeight();
      }

      resizeContainer($(this), targetHeight);

      if (isSectionWrapperVisible === false) {
        $("#section-wrapper").removeClass("invisible");
      }
    });
  }

  const resizeContainer = ($container, height) => {
    $container.css("min-height", height);
  }

  useEffect(() => {
    $(window).on('resize', () => {
      resizeSections();
      setTopOffset($('#header-container').outerHeight());
    });

    /// --- NOTE: React.useEffect is called on every render, so we can't use this anymore:
    /* sutil.scrollToSection(0, () => {
      setCurrentSectionId(0);
    }); */
    /// --- EON ---

    $(window).trigger('resize', () => {
      setIsSectionWrapperVisible(true);
    });

    return function cleanUp() {
      window.removeEventListener('resize', resizeSections);
    }
  });

  return (
    <>
      <Header onNavItemSelect={onNavItemSelect} currentSectionId={currentSectionId} />
      <Sections onSectionEnter={onSectionEnter} topOffset={topOffset} />
    </>
  );
}

export default App;
