/**
 * @description  ...
 *
 * @copyright (c) 2021 GRGRT, LLP.  Some rights reserved.
 * @author Steve Graegert <stv@grgrt.com>
 * @date 30.12.2021
 */

import { useState } from 'react';
import { Row } from 'react-bootstrap';

import { BooleanCell, ColumnHeader, SegmentHeader, TextCell } from './ServicesTableCells'

export function ServicesTable(props) {
  const [tableData, ] = useState(props.tableData);

  const renderHead = () => {
    return (
      <Row className="sticky-header mt-5 sticky-top">
        <SegmentHeader headerText={tableData.name} className={"segment-title"} />
        <ColumnHeader headerText={"Initiate"} />
        <ColumnHeader headerText={"Design"} />
        <ColumnHeader headerText={"Build"} />
        <ColumnHeader headerText={"Release"} />
      </Row>
    )
  }

  const renderRows = () => {
    return tableData.services.map((item, index) => {
      return (
        <Row className={`item-row`} key={index}>
          <TextCell value={item.service} />
          <BooleanCell value={item.i} />
          <BooleanCell value={item.d} />
          <BooleanCell value={item.b} />
          <BooleanCell value={item.r} />
        </Row>
      )
    });
  }

  return (
    <div data-id={props.dataId} className='service-table mt-5 mb-7'>
      {renderHead()}
      {renderRows()}
    </div>
  );
}
