/**
 * @description  ...
 *
 * @copyright (c) 2021 GRGRT, LLP.  Some rights reserved.
 * @author  Steve Graegert <stv@grgrt.com>
 * @date 30.12.2021
 */
import * as React from 'react';
import { Col } from 'react-bootstrap';

import './Services.scss'

/**
 * Renders the name (column 1) of each service in a segment.
 */
export function TextCell(props) {
  return (
    <Col xs="8" className="text item pt-2 pb-2">{props.value}</Col>
  );
}

/**
 * Renders a boolean value using a circle (false) and a disc (true).
 */
export function BooleanCell(props) {
  const value = props.value >= 1 ? 'selected' : '';

  return (
    <Col xs="1" className="boolean item pt-2 pb-2"><span className={value}>{''}</span></Col>
  );
}

export function ColumnHeader(props) {
  const headerText = props.headerText;
  const initial = props.headerText.substring(0, 1);

  return (
    <Col xs="1" className=''>
      <h3>
        <span className="d-none d-lg-block">{headerText}</span> {/* hide on screens smaller than lg */}
        <span className="d-lg-none">{initial}</span> {/* hide on lg and wider screens */}
      </h3>
    </Col>
  );
}

export function SegmentHeader(props) {
  const headerText = props.headerText;

  return (
    <Col xs="8" className={props.className + " item sticky-item"}><h3 className='text-uppercase'>{headerText}</h3></Col>
  );
}
