import { Welcome } from './welcome/Welcome';
import { Services } from './services/Services'
import { Values } from './values/Values';
import { Projects } from './projects/Projects';
import { Contact } from './contact/Contact';

import config from '../../config';

import './Sections.scss'

export function Sections(props) {
  const c = config.sections;

  return (
    <div id="section-wrapper" className='invisible'>
      <Welcome
        dataId={c.welcome.id}
        dataTitle={c.welcome.title}
        onSectionEnter={props.onSectionEnter}
        topOffset={props.topOffset}
        name={c.welcome.title} />
      <Services
        dataId={c.services.id}
        dataTitle={c.services.title}
        onSectionEnter={props.onSectionEnter}
        topOffset={props.topOffset}
        name={c.services.title} />
      <Values
        dataId={c.values.id}
        dataTitle={c.values.title}
        onSectionEnter={props.onSectionEnter}
        topOffset={props.topOffset}
        name={c.values.title} />
      <Projects
        dataId={c.projects.id}
        dataTitle={c.projects.title}
        onSectionEnter={props.onSectionEnter}
        topOffset={props.topOffset}
        name={c.projects.title} />
      <Contact
        dataId={c.contact.id}
        dataTitle={c.contact.title}
        onSectionEnter={props.onSectionEnter}
        topOffset={props.topOffset}
        name={c.contact.title} />
    </div>
  );
}