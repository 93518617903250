export function getTemplate(data) {
  return `<div class="container project-data">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.customer} (${data.displayCode})
            <h4>Customer (year)</h4>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.region}
            <h4>Location</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.scope}
            <h4>Scope</h4>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.industry}
            <h4>Industry</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.service}
            <h4>Service</h4>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-6">
            ${data.projectRole}
            <h4>Role</h4>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6">
        ${data.longText}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9 col-md-11 offset-11">
        <a href="#" class="project-data-close">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 29.001" style="enable-background:new 0 0 32 29.001;" xml:space="preserve">
            <path d="M17.608,14.5l-0.053-0.053L32,0H28.89L16,12.893L3.109,0H0l14.445,14.447L14.392,14.5l0.053,0.053L0,29.001h3.109L16,16.108 l12.89,12.892H32L17.554,14.553L17.608,14.5z"/>
          </svg>
        </a>
      </div>
    </div>
</div>`;
}
