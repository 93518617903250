import { Container, Row, Col } from 'react-bootstrap'
import { Waypoint } from 'react-waypoint';

import { ServicesTable } from './ServicesTable'
import { ServicesData } from '../../../data/services';

import './Services.scss'

export function Services(props) {
  const data = {
    strategy: ServicesData.strategy,
    insight: ServicesData.insight,
    methodology: ServicesData.methodology,
    education: ServicesData.education
  };

  return (
    <section id='services-section' data-id={props.dataId} data-title={props.dataTitle}>
      <Waypoint onEnter={(a) => props.onSectionEnter(a, props.dataId)} topOffset={props.topOffset} />
      <Container id='services-container' className='container-wrapper'>
        <h1>Services</h1>
        <Row className="mb-10">
          <Col xs={12} sm={6} md={6} lg={3} xl={3}>
            <h2>{data.strategy.name}</h2>
            <p>{data.strategy.description}</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3} xl={3}>
            <h2>{data.insight.name}</h2>
            <p>{data.insight.description}</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3} xl={3}>
            <h2>{data.methodology.name}</h2>
            <p>{data.methodology.description}</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3} xl={3}>
            <h2>{data.education.name}</h2>
            <p>{data.education.description}</p>
          </Col>
        </Row>
        <ServicesTable dataId="0" topOffset={props.topOffset} tableData={data.strategy} />
        <ServicesTable dataId="1" topOffset={props.topOffset} tableData={data.insight} />
        <ServicesTable dataId="2" topOffset={props.topOffset} tableData={data.methodology} />
        <ServicesTable dataId="3" topOffset={props.topOffset} tableData={data.education} />
      </Container>
    </section>
  );
}
