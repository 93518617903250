export const projects = [{
  "projectId": 0,
  "displayCode": "2017",
  "displayName": "Strategy",
  "customer": "Bundeswehr (BAAINBw H2.2)",
  "region": "Dresden, Germany",
  "scope": "initiate",
  "industry": "Defense",
  "service": "Strategy",
  "projectRole": "Lead Consultant",
  "longText": "<p>Groupware Bw (to be built on SharePoint 2016, Skype for Business and Exchange) is a critical component of the organization’s efforts to establish the elektronische Verwaltungsarbeit (eVA) as required by EGovG and outlined by the official programme Digital Government 2020. It complements existing projects within and beyond the organization to address increasing demands for an integrated, secure and standardized collaboration and publishing environment.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Supporting the PMO as an ECM/SharePoint subject matter expert.</li><li>Analysing the project’s viability to obtain a deep understanding of the cost structure.</li><li>Identifying risks and opportunities with a strong focus on ECM/SharePoint, its governance and delivery.</li></ul></p>"
}, {
  "projectId": 1,
  "displayCode": "2016",
  "displayName": "Software Development",
  "customer": "KVWL (Kassen&auml;rztliche Vereinigung Westfalen-Lippe)",
  "region": "Dortmund, Germany",
  "scope": "design, build",
  "industry": "Healthcare",
  "service": "Consulting",
  "projectRole": "Agile Master",
  "longText": "<p>Once the digital transformation project has been kicked off, focus quickly shifted towards modernization of employee and customer-facing business applications.  Coherent messaging is crucial for strong acceptance levels of both internal and external stakeholders.  The KVWL commisioned a re-engineering of its Intranet, at the same time consolidating and modernizing the Extranet, thereby providing a familiar user experience on all channels.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Following a user-centric approach to product development heloping the project team to get it right, the first time.</li><li>Gradually adopting a DevOps culture along with product releases to empower internal IT pros to rerspond to changing business needs in a more efficient, agile manner (thus creating tangible value to the organization).</li></ul></p>"
}, {
  "projectId": 2,
  "displayCode": "2015",
  "displayName": "IT Service design & transition",
  "customer": "Georg Thieme Verlag",
  "region": "Stuttgart, Germany",
  "scope": "initiate, design, build, release",
  "industry": "Media, publishing",
  "service": "Service design and transiton",
  "projectRole": "Transition manager",
  "longText": "<p>Starting in 2012 we have been creating a couple of awesome products ranging from a Webshop built with SAP and Hybris, an interactive web app for researching chemical as well as organic compounds called Römpp, to a database (known as VetCenter) helping vetenarians diagnose symptoms more reliably.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Transfering operational responsibility of critical business apps to a managed service provider without disrupting application availability for customers.</li></ul></p>"
}, {
  "projectId": 3,
  "displayCode": "2015",
  "displayName": "GIMS Summer Release 2015",
  "customer": "Wilhelmsen Holding ASA",
  "region": "Oslo, Norway",
  "scope": "design, build, release",
  "industry": "Nautical",
  "service": "Software product development",
  "projectRole": "Agile master",
  "longText": "<p>About three years ago we teamed up with Wilhelmsen to publish the first major release after going live with version 1.0. of its Global Integrated Management System based on SharePoint Server 2010 and ARIS.  Since that time we have been able to ship twice a year: a summer and winter release.  A critical aspect was the ability for both the customer and the dev team to successfully demonstrate that sustainable DevOps is possible at the global scale.  The last release shipped as planned thanks to the women and men in Norway, Vietnam, Malaysia and Germany.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Improving reliability of custom data adapters (KPI: >= 50% decrease of customer-reported defects).</li><li>Code base health check to verifying the apps readiness for being upgraded to SharePoint Server 2013.</li></ul></p>"
}, {
  "projectId": 4,
  "displayCode": "2014",
  "displayName": "Application Re-Engineering",
  "customer": "TenneT TSO GmbH",
  "region": "Bayreuth, Germany",
  "scope": "initiate, design",
  "industry": "Energy",
  "service": "Consulting",
  "projectRole": "Project Manager",
  "longText": "<p>TenneT’s LOB DIAS II was built on Documentum 6.x and was commissioned to be upgraded to Documentum 7 and the D2 client platform since EMC announced end of life for its WebTop client API. Next generation DIAS III A was the first business app created with D7/D2 proving the enterprise readiness of EMC’s flagship product.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Re-engineering the organization’s “Dateninformations- und Auskunftssystem” for EMC’s Documentum 7.</li><li>Providing technical and tactical expertise during contract negotionation with suppliers of required software components.</li></ul></p>"
}, {
  "projectId": 5,
  "displayCode": "2013",
  "displayName": "Product Development",
  "customer": "Thomas Sabo",
  "region": "N&uuml;rnberg, Germany",
  "scope": "initiate, design, build",
  "industry": "Retail",
  "service": "Consulting",
  "projectRole": "Project Manager",
  "longText": "<p>Thomas Sabo is a leading manufacturer and wholesaler of costume jewelry, who maintains long-standing relationships with retailers and suppliers all over the world. In 2012 we’ve been charged with the design and implementation of a SharePoint-based custom PLM solution tailored to the organisations unique business requirements.  SharePoint served as the UI layer, BizTalk as the business layer and Dynamics AX as primary data source of the company’s master data.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Providing stakeholders with a tool that consolidates data sources and serves as a single point of truth.</li><li>Implementing tool support for managing the company’s unique product lifecycle to increase process transparency, improve product quality and reduce operational costs.</li></ul></p>"
}, {
  "projectId": 6,
  "displayCode": "2012",
  "displayName": "Enterprise Application Integration",
  "customer": "Entireinfra GmbH",
  "region": "Dresden, Germany",
  "scope": "initiate, design",
  "industry": "Civil Engineering",
  "service": "Consulting",
  "projectRole": "Project Manager",
  "longText": "<p>Entireinfra is best known and most respected for its expertise in plant engineering and construction all over Europe. To keep its competitive edge we designed a highly integrated business software architecture including an efficient collaboration environment built with SharePoint 2010.<br />The latter provided the ability to query multiple data sources in order to obtain a consolidated view of related information sources to provide context for uses and improve productivity of information workers.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Establishing paperless processes for invoice managment and billing based on SharePoint and Nintex.</li><li>Integrating Microsoft Dynamics NAV with SharePoint to connect data with unstructured information.</li></ul></p>"
}, {
  "projectId": 7,
  "displayCode": "2011b",
  "displayName": "SharePoint ECM Deployment",
  "customer": "Sachsenfahnen GmbH &amp; Co. KG",
  "region": "Kamenz, Germany",
  "scope": "initiate, design, build",
  "industry": "Manufacturing",
  "service": "Consulting",
  "projectRole": "Lead Consultant",
  "longText": "<p>Sachsenfahnen specialises in screen printing, digital printing, finishing and subsequent technical treatment of textile, synthetic material, vinyl, glass, and metal, as well as hardware and display system solutions. Worldwide distribution and logistics complement the service portfolio.  As a long standing Dynamics customer one of the core requirements for its new ECM platform was a tight integration of Dynamics AX as well as connecting with the organisation’s business processes.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Designing and implementing an information architecture for QMS-related documentation including a records management strategy.</li><li>Replacement of the existing ELO DMS with SharePoint Server and re-design of relevant workflows with Nintex.</li></ul></p>"
}, {
  "projectId": 8,
  "displayCode": "2011a",
  "displayName": "Business Development",
  "customer": "Cosmo Consult GmbH",
  "region": "N&uuml;rnberg/Dresden/Berlin, Germany",
  "scope": "initiate, design, build, release",
  "industry": "Information Technology",
  "service": "Strategy",
  "projectRole": "Project Manager",
  "longText": "<p>The Cosmo Consult AG (formerly known as Tectura AG) is a trusted Dynamics partner for medium-sized and large businesses offering a wide range of services.  As SharePoint gained critical momentum, the decision to integrate the product with the organisation’s portfolio added to its competitive advantage.<br />Consequently, a cross-functional team has been assembled and tasked with creation of a business plan, implementation approach and project management plan for its new SharePoint business unit.</p><h3 class=\"underline\">Business Goals</h3><p><ul><li>Designing a comprehensive strategy for the creation of a new business unit complementing existing ones for Dynamic AX and NAV.</li><li>Fostering a lean entrepreneurial culture trancending mindsets and organizational borders.</li></ul></p>"
}, {
  "projectId": 9,
  "displayCode": "2010a",
  "displayName": "Enterprise Application Integration",
  "customer": "Osts&auml;chsische Baugesellschaft",
  "region": "Bautzen, Germany",
  "scope": "design, build",
  "industry": "Civil Engineering",
  "service": "Consulting",
  "projectRole": "Project Manager",
  "longText": "<p>The <em>Osts&auml;chsische Baugesellschaft</em> (OBAG) specializes in commercial and industrial building construction, public buildings, renovation and modernization, as well as the planning, construction and operation of biogas plants.</p><p>As part of its consolidation efforts the organization charged a team of specialists to take over the modernization of its ECM platform including the evaluation and design of options for integrating with its ERP.  We successfully accomplished the task thanks to JBoss enterprise-ready capabilities to serve as a high-performance service bus.</p>"
}, {
  "projectId": 10,
  "displayCode": "2009a",
  "displayName": "SharePoint Migration",
  "customer": "MB Services Gmbh &amp; Co. KG",
  "region": "N&uuml;rnberg, Germany",
  "scope": "initiate, design, build",
  "industry": "Food/beverages, pharmaceutical",
  "service": "Consulting",
  "projectRole": "Lead Consultant/Developer",
  "longText": "<p><em>MB Services</em> is a subsidiary of the Martin Bauer Group, a leading provider of premium quality herbals and botanicals operating in 26 countries on five continents.</p><p>With the announcement of <em>SharePoint Server 2010</em> and the availability of the first CTP we quickly started to initiate the migration of the existing infrastructure, based on MOSS 2007, including a complete overhaul of the user experience by re-branding applications, refactoring user/server controls and embracing the new client-object model and dialog concept, both of which have been higly anticipated by end users and developers alike.</p>"
}, {
  "projectId": 11,
  "displayCode": "2008a",
  "displayName": "Strategy Planning",
  "customer": "Lutz &amp; Grub",
  "region": "N&uuml;rnberg, Germany",
  "scope": "initiate, design",
  "industry": "Education",
  "service": "Strategy",
  "projectRole": "Lead Consultant",
  "longText": "<p>Although operating in the private sector <em>Lutz & Grub</em> is a trusted partner for NGOs and the public sector delivering high-quality educational services for the advancement of executives and decision makers.</p><p>By working closely with stakeholders and applying design tools and methods we have designed new and overhauled existing organizational concepts for employee education, enabling Lutz &amp; Grub to apply this innovative approach for in many organizations thereby increasing the efficiency of delivery and verfication of educational initiatives.</p>"
}, {
  "projectId": 12,
  "displayCode": "2007a",
  "displayName": "Self-Service Portal",
  "customer": "PhytoLab GmbH &amp; Co. KG",
  "region": "Poznan, Poland",
  "scope": "design, build",
  "industry": "Pharmaceutical",
  "service": "Consulting",
  "projectRole": "Project Manager",
  "longText": "<p>PhytoLab is an independent, accredited and officially recognised service laboratory for the analysis, development, quality control and management of regulatory affairs of medicinal products for the pharmaceutical industry.</p><p>The company commissioned the design and development of a self-service portal allowing customers to track orders, search for laboratory test results and apply for new certificates or renew expired ones. As for the marketing aspects the product was vital component of the organizations customer relationship management strategy.</p>"
}, {
  "projectId": 13,
  "displayCode": "2006b",
  "displayName": "Key Account Management",
  "customer": "Plantextrakt",
  "region": "Kleinostheim, Germany",
  "scope": "design, build",
  "industry": "Food/beverages, pharmaceutical",
  "service": "Consulting",
  "projectRole": "Lead Consultant",
  "longText": "<p><em>Plantextrakt</em> is a specialist when it comes to natural tea, herbal and fruit extracts, as well as decaffeinated black and green teas and tea flavours. The company, distributes more than 2,000 different recipes based on over 120 carefully selected and gently processed plants.</p><p>To keep its competitive edge, Plantextakt focuses on exceptional customer care and constantly educating its personnel to effectively maintain relationships with clients, large and small.  The <em>Key Account Management Portal</em> allows Account Managers to centrally access consolidated customer data that would otherwise be dispersed over countless applications and data sources.</p>"
}, {
  "projectId": 14,
  "displayCode": "2006a",
  "displayName": "Global Intranet",
  "customer": "MB Services Gmbh &amp; Co. KG",
  "region": "H&ouml;chstadt/Aisch, Germany",
  "scope": "plan, design, release",
  "industry": "Food/beverages",
  "service": "Consulting, strategy",
  "projectRole": "Lead Consultant",
  "longText": "<p><em>MB Services</em> is a subsidiary of the Martin Bauer Group, a leading provider of premium quality herbals and botanicals operating in 26 countries on all continents.</p><p>Thanks to the Microsoft TAP initiative (<em>technology adaption program</em>) we had the privilege to employ the latest SharePoint release (a.k.a. <em>Microsoft Office SharePoint Server 2007</em>) to design, build and deploy a global Intranet with an international audience of about 3.500 users.</p>"
}, {
  "projectId": 15,
  "isDisabled": true
}];
