import { useState } from 'react';
import { Col, Container, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'

import './Header.scss'

export function Header(props) {
  const [modal, setModal] = useState(false);

  const onSelect = (sectionId) => {
    toggle();
    props.onNavItemSelect(sectionId);
  }

  const toggle = () => {
    setModal(!modal);
  }

  const id = props.currentSectionId;

  return (
    <Container id='header-container' className='fixed-top'>
      <Row>
        <Col xs={6}>
          <h1 id="header" className="pt-3">Welcome</h1>
        </Col>
        <Col xs={6} className='text-end'>
          <a id="logo-type" className="navbar-toggler navbar-toggler-right border-0 pt-4 d-none d-lg-block" type="button" onClick={toggle} data-toggle="collapse" data-target="#navbar-toggle-external-content" aria-controls="navbar-toggle-external-content" aria-expanded="false" aria-label="Toggle navigation">
            <span className="">GRGRT</span> {/* hide on screens smaller than lg */}
          </a>
          <nav id="page-nav" className="navbar navbar-light">
            <a className='navbar-brand'>&nbsp;</a>
            <a className="navbar-toggler border-0" type="button" onClick={toggle} data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon d-lg-none"></span> {/* hide on lg and wider screens */}
            </a>
          </nav>
        </Col>
      </Row>
      <Modal show={modal} fullscreen={true} onHide={toggle} id="nav-popup">
        <Modal.Header closeButton>
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush" className="text-center">
            <ListGroupItem onClick={() => onSelect(0)} tag="button" action className="justify-content-center" active={id === 0}>Welcome</ListGroupItem>
            <ListGroupItem onClick={() => onSelect(1)} tag="button" action className="justify-content-center" active={id === 1}>Services</ListGroupItem>
            <ListGroupItem onClick={() => onSelect(2)} tag="button" action className="justify-content-center" active={id === 2}>Values</ListGroupItem>
            <ListGroupItem onClick={() => onSelect(3)} tag="button" action className="justify-content-center" active={id === 3}>Projects</ListGroupItem>
            <ListGroupItem onClick={() => onSelect(4)} tag="button" action className="justify-content-center" active={id === 4}>Contact</ListGroupItem>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
