import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Waypoint } from 'react-waypoint';
import './Welcome.scss'

export function Welcome(props) {
  return (
    <section id="welcome-section" data-id={props.dataId} data-title={props.dataTitle}>
      <Waypoint onEnter={(a) => props.onSectionEnter(a, props.dataId)} topOffset={props.topOffset} />
      <Container id='welcome-container' className='container-wrapper'>
        <h1 className='pt-3'>Welcome</h1>
        <Row>
          <Col xs={12} sm={12} md={12} lg={9} xl={7}>
            <p className='statement'>I am Steve, a <span className='highlight'>Business Consultant</span> based in Berlin specialized in technology consulting and organizational strategy design.</p>
            <p className='subtext'>The focus of my work is to help customers across all industries identify opportunities for innovation and act on them.</p>
          </Col>
          <Col xs={2}>&nbsp;</Col>
        </Row>
      </Container>
    </section>
  );
}
